export const vipRouterArr = [
  {
    path: "/vip", //会员卡首页
    name: "首页",
    component: () => import("@/views/vip/home"),
    redirect: () => {
      return "/viphome";
    },
    meta: {
      noAuth: false
    },
    children: [
      {
        path: "/integralshop", //会员卡积分列表
        name: "积分商城",
        component: () => import("@/views/vip/child/integralshop")
      },
      {
        path: "/viphome", //会员卡首页
        name: "首页",
        component: () => import("@/views/vip/vip_mycenter")
      },
      {
        path: "/vippersonal", //个人中心
        name: "个人中心",
        component: () => import("@/views/vip/vip_person")
      },
      {
        path: "/vipShopList", // 店铺列表
        name: "附近油站",
        component: () => import("@/views/vip/vipShopList")
      },
      {
        path: "/vip_mycenter", // 会员中心
        name: "会员中心",
        component: () => import("@/views/vip/vip_mycenter")
      }
    ]
  },
  {
    path: "/vip_detail",
    name: "文章详情",
    component: () => import("@/views/vip/vip_detail"),
  },
  {
    path: "/cardpay", //会员卡支付
    name: "支付",
    component: () => import("@/views/vip/newPay/newConfirmOrder"),
  },
  {
    path: "/couponCenter", // 领券中心
    name: "领券中心",
    component: () => import("@/views/vip/child/couponCenter")
  },
  {
    path: "/pay_success", //兑换订单详情
    name: "支付成功",
    component: () => import("@/views/vip/child/pay_success")
  },
  {
    path: "/station_des", //油站详情
    name: "油站详情",
    component: () => import("@/views/vip/child/stationDetails")
  },
  {
    path: "/checkin", //会员卡签到
    name: "会员卡签到",
    component: () => import("@/views/vip/sign_In")
  },
  {
    path: "/bindPhone", // 手机登录
    name: "手机登录",
    component: () => import("@/views/vip/goVip")
  },
  {
    path: "/money", //vip充值
    name: "Vip充值",
    component: () => import("@/views/vip/save_money")
  },
  {
    path: "/integral", //会员卡积分列表
    name: "会员卡积分",
    component: () => import("@/views/vip/integral_list")
  },
  {
    path: "/pay_type", //支付方式
    name: "支付方式",
    component: () => import("@/views/vip/child/payType")
  },
  {
    path: "/invoice_mag", //发票管理
    name: "发票管理",
    component: () => import("@/views/vip/invoice")
  },
  {
    path: "/coupon", //优惠券列表
    name: "优惠券",
    component: () => import("@/views/vip/child/couponList")
  },
  {
    path: "/cpn_detail", //优惠券详情
    name: "优惠券详情",
    component: () => import("@/views/vip/child/couponDetail")
  },
  {
    path: "/vip_userInfo", //用户信息
    name: "用户信息",
    component: () => import("@/views/vip/child/showPerson")
  },
  {
    path: "/dredge_card", //开通会员卡
    name: "开通会员卡",
    component: () => import("@/views/vip/dredge_card")
  },
  {
    path: "/rechargelist", // 充值记录
    name: "充值记录",
    component: () => import("@/views/vip/child/rechargelist")
  },
  {
    path: "/balanceRecord", // 余额记录
    name: "余额记录",
    component: () => import("@/views/vip/child/balanceRecord")
  },
  {
    path: "/integralRecord", // 积分记录
    name: "商城订单",
    component: () => import("@/views/vip/child/integralRecord")
  },
  {
    path: "/shareLink", // 分享邀请
    name: "分享邀请",
    component: () => import("@/views/vip/shareLink")
  },
  {
    path: "/giftcard", // 礼品卡
    name: "礼品卡",
    component: () => import("@/views/vip/child/giftcard")
  },
  {
    path: "/viporder", // 订单
    name: "订单",
    component: () => import("@/views/vip/vip_orderList")
  },
  {
    path: "/goVip", // 手机登录
    name: "手机登录",
    component: () => import("@/views/vip/goVip")
  },
  {
    path: "/saveInfo", // 完善资料
    name: "完善资料",
    component: () => import("@/views/vip/saveInfo")
  },
  {
    path: "/password", // 修改密码
    name: "修改密码",
    component: () => import("@/views/vip/child/changePwd")
  },

  {
    path: "/chargeoff", //会员卡核销
    name: "核销",
    component: () => import("@/views/vip/child/chargeoff")
  },
  {
    path: "/confirmOrder", // 确认订单
    name: "确认订单",
    component: () => import("@/views/vip/confirmOrder")
  },
  {
    path: "/invoice", // 发票信息
    name: "发票信息",
    meta: {
      title: "发票信息"
    },
    component: () => import("@/views/vip/child/invoiceInvoice")
  },
  {
    path: "/carInfor", // 车辆信息
    name: "车辆信息",
    meta: {
      title: "车辆信息"
    },
    component: () => import("@/views/vip/child/carInfor")
  },
  {
    path: "/payewm", // 支付二维码
    name: "支付",
    meta: {
      title: "支付"
    },
    component: () => import("@/views/vip/payewm")
  },
  {
    path: "/tgLogin", // 推广登录
    name: "登录",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/generalize/Login")
  },
  {
    path: "/user_promotion", // 推广主页
    name: "推广主页",
    meta: {
      title: "推广主页"
    },
    component: () => import("@/views/generalize/promotion/UserPromotion")
  },
  {
    path: "/point_order", // 商城订单
    name: "商城订单",
    meta: {
      title: "商城订单"
    },
    component: () => import("@/views/vip/child/point_order")
  },
  {
    path: "/poster", // 邀请码
    name: "邀请码",
    meta: {
      title: "邀请码"
    },
    component: () => import("@/views/generalize/promotion/Poster")
  },
  {
    path: "/UserCash", // 去提现
    name: "提现",
    meta: {
      title: "提现"
    },
    component: () => import("@/views/generalize/promotion/UserCash")
  },
  {
    path: "/withdrawalRecord", // 提现记录
    name: "提现记录",
    meta: {
      title: "提现记录"
    },
    component: () => import("@/views/generalize/promotion/withdrawalRecord")
  },
  {
    path: "/PromoterList", // 推荐人列表
    name: "推荐人列表",
    meta: {
      title: "推荐人列表"
    },
    component: () => import("@/views/generalize/promotion/PromoterList")
  },

  {
    path: "/newPay", // 推荐人列表
    name: "油品买单",
    meta: {
      title: "油品买单"
    },
    component: () => import("@/views/vip/newPay/newPay")
  },
  {
    path: "/goMoney", // 推荐人列表
    meta: {
      title: "输入金额"
    },
    component: () => import("@/views/vip/newPay/goMoney")
  },
  {
    path: "/oilsCardList", // 油卡列表
    name: "油卡列表",
    meta: {
      title: "我的加油卡"
    },
    component: () => import("@/views/vip/refuelCard/oilsCardList")
  },
  {
    path: "/oilCardDetail", // 加油卡详情
    name: "油卡详情",
    meta: {
      title: "加油卡详情"
    },
    component: () => import("@/views/vip/refuelCard/oilCardDetail")
  },
  {
    path: "/storedValue", // 充值
    name: "充值",
    meta: {
      title: "充值"
    },
    component: () => import("@/views/vip/refuelCard/storedValue")
  },
  {
    path: "/time_recharge_pay", // 充值
    name: "正在充值",
    meta: {
      title: "充值"
    },
    component: () => import("@/views/vip/refuelCard/time_recharge_pay")
  },
  {
    path: "/authentication", // 身份认证
    name: "身份认证",
    meta: {
      title: "身份认证"
    },
    component: () => import("@/views/vip/refuelCard/authentication")
  },
  {
    path: "/rechargSucceed", // 认证成功
    meta: {
      title: "认证成功"
    },
    component: () => import("@/views/vip/refuelCard/rechargSucceed")
  },
  {
    path: "/vipUpCondition", // 升级条件
    meta: {
      title: "升级条件"
    },
    component: () => import("@/views/vip/child/vipUpCondition")
  },
  {
    path: "/accountList", // 切换账号
    meta: {
      title: "切换账号"
    },
    component: () => import("@/views/vip/child/accountList")
  },
  {
    path: "/beUpdating", // 系统更新提示
    name: "系统更新",
    meta: {
      title: "系统更新"
    },
    component: () => import("@/views/vip/beUpdating")
  },
  {
    path: "/carteamCard", // 车队管理
    name: "车队管理",
    meta: {
      title: "车队管理"
    },
    component: () => import("@/views/vip/carteam/carteamCard.vue")
  },
  {
    path: "/dredgePage", // 添加车队卡
    name: "添加车队卡",
    meta: {
      title: "添加车队卡"
    },
    component: () => import("@/views/vip/carteam/dredgePage.vue")
  },
  {
    path: "/sendCard", // 发卡
    name: "发卡",
    meta: {
      title: "发卡"
    },
    component: () => import("@/views/vip/carteam/sendCard.vue")
  },
  {
    path: "/sendCardSuccess", // 发卡成功
    name: "发卡成功",
    meta: {
      title: "发卡成功"
    },
    component: () => import("@/views/vip/carteam/sendCardSuccess.vue")
  },
  {
    path: "/driverList", // 司机列表
    name: "司机列表",
    meta: {
      title: "司机列表"
    },
    component: () => import("@/views/vip/carteam/driverList.vue")
  },
  {
    path: "/driverdetils", // 司机详情
    name: "司机详情",
    meta: {
      title: "司机详情"
    },
    component: () => import("@/views/vip/carteam/driverdetils.vue")
  },
  {
    path: "/consumeRecord", // 消费记录
    name: "消费记录",
    meta: {
      title: "消费记录"
    },
    component: () => import("@/views/vip/carteam/consumeRecord.vue")
  },
  {
    path: "/teamCardList", // 车队卡列表
    name: "车队卡列表",
    meta: {
      title: "车队卡列表"
    },
    component: () => import("@/views/vip/carteam/teamCardList.vue")
  },
  {
    path: "/kinsfolkCardList", // 亲属卡列表
    name: "亲属卡列表",
    meta: {
      title: "亲属卡列表"
    },
    component: () => import("@/views/vip/kinsfolkCard/kinsfolkCardList.vue")
  },
  {
    path: "/sendKinsCard", // 开通亲属卡
    name: "开通亲属卡",
    meta: {
      title: "开通亲属卡"
    },
    component: () => import("@/views/vip/kinsfolkCard/sendKinsCard.vue")
  },
  {
    path: "/teamCardRecord", // 车队子卡记录
    name: "车队子卡记录",
    meta: {
      title: "车队子卡记录"
    },
    component: () => import("@/views/vip/refuelCard/teamCardRecord.vue")
  },
  {
    path: "/sendfamilyCards", // 亲属卡消费记录
    name: "亲属卡消费记录",
    meta: {
      title: "亲属卡消费记录"
    },
    component: () => import("@/views/vip/kinsfolkCard/sendfamilyCards.vue")
  },
  // 分销商条件
  {
    path: "/dis_entry",
    name: "分销商",
    meta: {
      title: "分销商"
    },
    component: () => import("@/views/vip/distribution/dis_entry.vue")
  },
  // 邀请好友
  {
    path: "/invitation",
    name: "邀请好友",
    meta: {
      title: "邀请好友"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/invitation.vue")
  },
  {
    path: "/open_card",
    name: "拆礼包",
    meta: {
      title: "拆礼包"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/OpenJiangLi.vue")
  },
  {
    path: "/un_user",
    name: "不能领取礼包",
    meta: {
      title: "不能领取礼包"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/un_user.vue")
  },
  {
    path: "/oil_spread",
    name: "分销商",
    meta: {
      title: "分销商"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/UserPromotion.vue")
  },
  {
    path: "/oil_promoter",
    name: "推广统计",
    meta: {
      title: "推广统计"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/PromoterList.vue")
  },
  {
    path: "/commissionLogs",
    name: "佣金记录",
    meta: {
      title: "佣金记录"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/PromoterOrder.vue")
  },
  // 提现记录
  {
    path: "/mylrecord_list",
    name: "提现记录",
    meta: {
      title: "提现记录"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/withdrawalRecord.vue")
  },
  // 提现
  {
    path: "/mycash",
    name: "立即提现",
    meta: {
      title: "立即提现"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/UserCash.vue")
  },
    {
    path: "/apply_change",
    name: "油站列表",
    meta: {
      title: "油站列表"
    },
    component: () => import("@/views/vip/vip_template/apply_change.vue")
  },
  {
    path: "/spread_order",
    name: "分销订单",
    meta: {
      title: "油站列表"
    },
    component: () => import("@/views/vip/distribution/generalize/promotion/FenOrder.vue")
  },
  {
    path: "/turntable",
    name: "大转盘",
    meta: {
      title: "幸运抽奖~"
    },
    component: () => import("@/views/vip/child/turntable.vue")
  },
  {
    path: "/winningRecord",
    name: "中奖记录",
    meta: {
      title: "我的奖品"
    },
    component: () => import("@/views/vip/child/winningRecord.vue")
  },
  {
    path: "/washCar",
    name: "洗车服务",
    meta: {
      title: "洗车服务"
    },
    component: () => import("@/views/vip/child/washCar/index.vue")
  },
  {
    path: "/oil_price_show",
    name: "油价展示",
    meta: {
      title: "今日油价"
    },
    component: () => import("@/views/vip/oil_price_show.vue")
  },
  {
    path: "/washCarDetail",
    name: "洗车店铺详情",
    meta: {
      title: "洗车店铺详情"
    },
    component: () => import("@/views/vip/child/washCar/singleJoin.vue")
  },
  {
    path: "/washCarOrder",
    name: "洗车订单",
    meta: {
      title: "洗车订单"
    },
    component: () => import("@/views/vip/child/washCar/order.vue")
  },
  {
    path: "/washCarOrderDetail",
    name: "洗车订单详情",
    meta: {
      title: "洗车订单详情"
    },
    component: () => import("@/views/vip/child/washCar/orderDetail.vue")
  },
  {
    path: "/qrcode",
    name: "二维码名片",
    meta: {
      title: "二维码名片"
    },
    component: () => import("@/views/personal/qrcode.vue")
  }
]


