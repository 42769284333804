import setting from "@api/setting";
import { setMoney } from "@/utils/tools";
/**
 * 用户模块状态操作
 */
const DiscountInfo = {
  state: {
    initDiscountArr: [], // 默认使用优惠
    userDiscountArr: [],  // 用户选择优惠
    disAllMoney: {
      dis_money: 0,
      end_money: 0,
    },
    now_UserDisKey: "",
    now_disArr: [],
    recharge_dismoney: 0,
  },
  mutations: {
    updateAllDisMoney(state, dismoney) {
      let data = Object.assign({}, state.disAllMoney,dismoney); 
      state.disAllMoney = data;
    },
    setNowUserKey(state, key) {
      state.now_UserDisKey = key;
    },
    setInitDiscountArr(state, info) {
      const { dis_str, point_status, dis_info, money } = info;
      var result = {};
      let initArr = dis_str.split(',');
      var arr = [];
      if (point_status) {
        arr = ['1', '2', '3'];
      } else {
        arr = ['2', '3']
      }
      let initDiscountArr = [];
      // 未设置互斥方式
      console.log(dis_str)
      if (dis_str) {
        initDiscountArr = arr.filter(item => initArr.indexOf(item) < 0);
      } else {
        initDiscountArr = arr;
        initArr = [];
      }
      state.initDiscountArr = initDiscountArr;
      state.userDiscountArr = initArr;
      if(initArr.length > 0) {
        state.now_disArr = initDiscountArr.concat(initArr[0]);
        state.now_UserDisKey = initArr[0].toString();
        result = setMoney(initDiscountArr.concat(initArr[0]), money, dis_info);
      } else {
        state.now_disArr = initDiscountArr;
        result = setMoney(initDiscountArr, money, dis_info);
      }
      if (dis_info) {
        dis_info.dis_money = result.dis_money;
        dis_info.end_money = result.end_money;
      }
      state.disAllMoney = dis_info;
    },
    
    updateDisMoney(state, info) {
      const { now_dis, money, arr, type } = info;
      let dis_arr = [];
      if (type == 2) {
        dis_arr = arr;
      } else {
        dis_arr = arr ? arr:state.initDiscountArr.concat(now_dis);
      }
      state.now_disArr = dis_arr;
      const obj = setMoney(dis_arr, money, state.disAllMoney);
      if (obj) {
        let money_info = state.disAllMoney;
        money_info.dis_money = obj.dis_money;
        money_info.end_money = obj.end_money;
        state.disAllMoney = money_info;
      }
    },
  },
  actions: {
    // 计算优惠金额
    async computeMoney({ commit }, data) {
      try {
        const res = await setting.getCompute(data);
        if (res.code === 200) {
          let offline_buy = res.data.preferce_set.offline_buy;
          if (offline_buy == "") {
            commit('setInitDiscountArr', {
              dis_str: "",
              point_status: res.data.open_point,
              dis_info: res.data,
              money: data.money
            })
          } else {
            commit('setInitDiscountArr', {
              dis_str: offline_buy,
              point_status: res.data.open_point,
              dis_info: res.data,
              money: data.money
            })
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 更新当前优惠内容
    async updateNowDismoney({ commit }, data) {
      try {
        const res = await setting.getCompute(data);
        if (res.code === 200) {
          commit('updateAllDisMoney', res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 余额支付 不使用的优惠
    async noUsedDisArr({ commit, state }, data) {
      try {
        const res = await setting.getBalanceSet();
        let { arr, money } = data;
        arr = Array.from(new Set(arr));
        if (res.code == 200) {
          const { coupon_status, points_status, user_level, recharge_coupon, recharge_discount, recharge_point } = res.data;
          console.log(`优惠券${coupon_status} 积分 ${points_status} 会员 ${user_level}`);
          if (points_status) {
            let idx = arr.indexOf('1');
            if (idx >= 0) {
              arr.splice(idx, 1);
            }
          }
          if (coupon_status) {
            let idx = arr.indexOf('2');
            if (idx >= 0) {
              arr.splice(idx, 1);
            }
          }
          if (user_level) {
            let idx = arr.indexOf('3');
            if (idx >= 0) {
              arr.splice(idx, 1);
            }
          }
          if (recharge_coupon && Number(state.recharge_dismoney) > 0) {
            let idx_key_1 = arr.indexOf('2');
            if (idx_key_1 >= 0 ) {
              arr.splice(idx_key_1, 1);
            }
          }
          if (recharge_discount && Number(state.recharge_dismoney) > 0) {
            let idx_key_2 = arr.indexOf('3');
            if (idx_key_2 >= 0) {
              arr.splice(idx_key_2, 1);
            }
          }
          if (recharge_point && Number(state.recharge_dismoney) > 0) {
            let idx_key_3 = arr.indexOf('1');
            if (idx_key_3 >= 0) {
              arr.splice(idx_key_3, 1);
            }
          }
          commit('updateDisMoney', { arr, money } );
        } else {
          commit('updateDisMoney', { arr, money } );
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  getters: {
    disAllMoney: (state) => {
      return state.disAllMoney;
    },
    userDiscountArr: (state) => {
      return state.userDiscountArr;
    },
    initDiscountArr: (state) => {
      return state.initDiscountArr;
    },
    now_UserDisKey: (state) => {
      return state.now_UserDisKey;
    }
  }
}

export default DiscountInfo
