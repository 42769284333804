import { post, App_ID } from "./publicHeader";
import { getSystemEnv } from "../utils/tools";
class Setting {
  // 获取密保问题选项列表
  async getQuestionSelections(ids) {
    try {
      const res = await post('/shop/user.index/secretQuestion', {
        app_id: App_ID,
        select_ids:ids
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 判断是否实名认证
  async IfIdCard() {
    try {
      const res = await post('/shop/user.index/judgeIdcard', {
        app_id: App_ID,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 判断是否设置密保问题
  async IfQuestion() {
    try {
      const res = await post('/shop/user.index/judgeSecretQuestion', {
        app_id: App_ID,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 验证登陆密码是否正确
  async verifyPass(p) {
    try {
      const res = await post('/shop/user.index/verifyPass', {
        app_id: App_ID,
        password:p
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 验证交易密码是否正确
  async verifyPayPass(p,type,account_id) {
    try {
      const res = await post('/shop/user.index/verifyPayPass', {
        app_id: App_ID,
        pay_pass:p,
        type,
        account_id
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 判断用户是否已设置 
  async IfVerify(type) {
    try {
      const res = await post('/shop/user.index/judgeField', {
        app_id: App_ID,
        field: type
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 判断用户是否有支付密码
  async judgePayPass() {
    try {
      const res = await post('/shop/user.index/judgePayPass', {
        app_id: App_ID,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 修改用户登陆密码
  async EditPassword(password) {
    try {
      const res = await post('/shop/user.index/editInfo', {
        app_id: App_ID,
        password:password
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 修改用户手机号
  async EditMobile(mobile) {
    try {
      const res = await post('/shop/user.index/editInfo', {
        app_id: App_ID,
        mobile:mobile
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 修改用户支付密码
  async EditPayPassword(pay_password) {
    try {
      const res = await post('/shop/user.index/editInfo', {
        app_id: App_ID,
        pay_password:pay_password
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 提交实名认证信息
  async SetCert(data) {
    try {
      const res = await post('/shop/user.index/setCert', {
        app_id: App_ID,
        name:data.name,
        sex:data.sex,
        idcard:data.idcard,
        sign_org:data.sign_org,
        front:data.front,
        back:data.back,
        hand:data.hand,
        photo:data.photo,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 上传图片
  async upImg(image, file_group) {
    try {
      const res = await post("/shop/user.upload/uploads", {
        app_id: App_ID,
        image: image,
        file_group
      })
      return res;
    } catch (err) {
      console.log(err)
    }
  }

  //获取用户个人信息
  async GetInfo() {
    try {
      const res = await post('/shop/user.index/info', {
        app_id: App_ID,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  //修改用户个人信息
  async EditUserInfo(data) {
    try {
      const res = await post('/shop/user.index/editInfo', {
        app_id: App_ID,
        headimgurl:data.headimgurl,
        real_name:data.real_name,
        sex:data.sex,
        birthday:data.birthday,
        sign:data.sign
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 获取邮费
  async getUserFreight(goods_id, address_id, num) {
    try {
      const res = await post('/shop/user.order/getFreight', {
        app_id: App_ID,
        goods_id: goods_id,
        address_id: address_id,
        num: num
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 获取支付方式
  async getUserPay() {
    try {
      const res = await post('/offlinestore/user.recharge/pay_type', {
        app_id: App_ID,
        source: getSystemEnv()
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 应用列表
  async getApplyList(page, app_str) {
    try {
      const res = await post('/offlinestore/user.store/leader_room', {
        app_id: App_ID,
        page,
        app_str
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 转盘默认配置
  async roundConfig(amount = "", goods_id = "") {
    try {
      const res = await post('/bigwheel/user.index/get_default', {
        app_id: App_ID,
        store_id: 0,
        amount,
        goods_id
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 获奖列表
  async getMyward(page, type = 1) {
    try {
      const res = await post('/bigwheel/user.index/my_award ', {
        app_id: App_ID,
        page,
        type
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 获取转盘中奖信息
  async getWinningInfo(id, money) {
    try {
      const res = await post('/bigwheel/user.index/round', {
        app_id: App_ID,
        id,
        money
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 展示核销码
  async showCode(id) {
    try {
      const res = await post('/bigwheel/user.index/my_award_detail', {
        app_id: App_ID,
        id,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 获取代理页面配置信息
  async getPageConfig() {
    try {
      const res = await post('/user/user.index/get_button', {
        app_id: App_ID,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  async getBalanceSet() {
    try {
      const res = await post('/api/tool/balance_set', {
        app_id: App_ID,
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 计算优惠
  async getCompute(obj) {
    try {
      const res = await post('/offlinestore/user.pay/compute', {
        app_id: App_ID,
        ...obj
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }

  // 是否采用新支付方式
  async isUsedNewPay(url) {
    try {
      return await post('/client/index/new_index', {
        app_id: App_ID,
        url
      })
    } catch(err) {
      console.log(err);
    }
  }
  
}

export default new Setting();
