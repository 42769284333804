import Vue from "vue";
import Router from "vue-router";
import test from "./test";
Vue.use(Router);

export const mainRoutes = [
  {
    path: "/forumSearch",
    component: () => import("@/views/forum/items/forumSearch")
  },
  {
    path: "/enjoyshop",
    component: () => import("@/views/home/enjoyshop/bottombar"),
    redirect: () => {
      return "shopindex";
    },
    meta: {
      isUseCache: false,
      keepAlive: true // 需要缓存
    },
    beforeRouteLeave(to, from, next) {
      if (to.path == "ShopDetail") {
        from.meta.isUseCache = true;
      }
      next();
    },
    children: [
      ...test,
      {
        path: "/shopindex",
        component: () => import("@/views/home/enjoyshop/index")
      },
      {
        path: "/goods",
        component: () => import("@/views/home/enjoyshop/SortGoods")
      },
      {
        path: "/shopping",
        component: () => import("@/views/shopping/index")
      },

      {
        path: "/personal",
        component: () => import("@/views/personal/index")
      }
    ]
  },
  {
    path: "/order",
    component: () => import("@/views/shopping/OrderList")
  },
  {
    path: "/ShopDetail",
    component: () => import("@/views/home/enjoyshop/ShopDetail")
  },
  {
    path: "/forumDetail",
    component: () => import("@/views/forum/items/forumDetail")
  },
  {
    path: "/discuss",
    component: () => import("@/views/forum/items/discuss")
  },
  {
    path: "/postMessage",
    component: () => import("@/views/forum/items/postMessage")
  },
  {
    path: "/addAddress",
    component: () => import("@components/public/addAddress")
  },
  {
    path: "/makeorder",
    component: () => import("@/views/shopping/MakeOrder")
  },
  {
    path: "/addresslist",
    component: () => import("@components/public/setaddress")
  },
  {
    path: "/userlogin",
    meta: {
      title: "用户信息"
    },
    component: () => import("@/views/login/userlogin")
  },
  {
    path: "/orderDetails",
    component: () => import("@/views/shopping/orderDetails")
  },
  {
    path: "/evaluate",
    component: () => import("@/views/shopping/evaluate")
  },
  {
    path: "/shopEvaluate",
    component: () => import("@/views/shopping/shopEvaluate")
  },

  {
    path: "/search",
    component: () => import("@/views/search/index"),
    meta: {
      isUseCache: false,
      keepAlive: true // 需要缓存
    }
  },
  {
    path: "/businezhussesin", //商家入驻介绍
    meta: {
      title: "商家入驻"
    },
    component: () => import("@/views/home/businessesin/info")
  },
  {
    path: "/businesseform", //商家入驻表单
    meta: {
      title: "商家入驻"
    },
    component: () => import("@/views/home/businessesin/form")
  },
  {
    path: "/selectMap", //选择地图
    name: "selectmap",
    meta: {
      title: "选择地图"
    },
    component: () => import("@/views/home/businessesin/selectMap")
  },
  {
    path: "/groupbuy", //乐享团购首页
    meta: {
      title: "首页"
    },
    component: () => import("@/views/home/groupbuy/index")
  },
  {
    path: "/hotrank", //热销榜
    meta: {
      title: "热销榜"
    },
    component: () => import("@/views/home/groupbuy/HotRank")
  },
  {
    path: "/storerank", //商家榜
    meta: {
      title: "商家榜"
    },
    component: () => import("@/views/home/groupbuy/StoreRank")
  },
  {
    path: "/choice", //为您优选
    meta: {
      title: "为您优选"
    },
    component: () => import("@/views/home/groupbuy/choice")
  },
  {
    path: "/setting", //账户设置
    meta: {
      title: "账户设置"
    },
    component: () => import("@/views/personal/setting")
  },
  {
    path: "/userinfo", //个人信息
    meta: {
      title: "个人信息"
    },
    component: () => import("@/views/personal/UserInfo")
  },
  {
    path: "/edituserinfo", //修改昵称，个性签名
    meta: {
      title: "修改"
    },
    component: () => import("@/views/personal/editUserInfo")
  },
  {
    path: "/accountsafety", //账户安全
    meta: {
      title: "账户安全"
    },
    component: () => import("@/views/personal/accountSafety")
  },
  {
    path: "/logout", //注销账户
    meta: {
      title: "注销账户"
    },
    component: () => import("@/views/personal/logout")
  },
  {
    path: "/userSafety", //个人中心-手机号码
    meta: {
      title: "个人中心"
    },
    component: () => import("@/views/personal/UserSafety")
  },
  {
    path: "/verify", //用户验证(登陆密码，手机号，交易密码，密保问题)
    meta: {
      title: "用户验证"
    },
    component: () => import("@/views/personal/verify")
  },
  {
    path: "/realName", //实名认证
    meta: {
      title: "实名认证"
    },
    component: () => import("@/views/personal/realName")
  },
  {
    path: "/question", //实名认证
    meta: {
      title: "商家入驻介绍"
    },
    component: () => import("@/views/personal/question")
  },
  {
    path: "/storedet", //店铺
    meta: {
      title: "店铺"
    },
    component: () => import("@/views/store/store_detail")
  },
  {
    path: "/vipmessage", //会员卡积分列表
    meta: {
      title: "积分列表"
    },
    component: () => import("@/views/vip/child/leaveMessage")
  },

  {
    path: "/integraldetail", //会员卡积分详情
    meta: {
      title: "积分商品详情"
    },
    component: () => import("@/views/vip/child/integraldetail")
  },
  {
    path: "/exchange_detail", //兑换订单详情
    meta: {
      title: "兑换订单详情"
    },
    component: () => import("@/views/vip/integral_list_detail")
  },
];
