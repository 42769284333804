import Vue from 'vue'
import Router from 'vue-router'
import { mainRoutes } from './shop'
// import { timeStamp } from "@/utils/tools";
import { vipRouterArr } from './vip'
import { Tools } from './tools'
import test from "./test"
import { Dialog } from "vant";
import { setConfig } from '../lin/utils/sys_config'

Vue.use(Router)

export const constantRoutes = [
  {
    path: "/",
    component: () => import('@/views/all/index'),
    redirect: () => {
      return "/vip_mycenter"
    }
  },
  ...vipRouterArr,
  ...mainRoutes,
  ...test,
  ...Tools
]

const createRouter = () => new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  },
  routes: constantRoutes
})

const router = createRouter()
router.beforeEach((to, from, next) => {
  if (to.path == "/cardpay" && to.query.time) {
    var dateTime = new Date().getTime() / 1000;
    var num = (dateTime - to.query.time) / 60;
    if (num > 5) {
      Dialog.alert({
        title: '此链接已过期，请重新扫码获取!',
      }).then(() => {
        window.location.href = "https://jjtc.58zltc.com/wxScan"
      });
    } else {
      return setConfig(to, from, next);
    }
  } else {
    return setConfig(to, from, next);
  }
});


export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}


export default router
