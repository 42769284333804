/* eslint-disable class-methods-use-this */
import { post, App_ID, get } from "./publicHeader";

// import { Toast } from "vant";
// import router from "@/router/index";
class UserInfo {
  username = null; // 昵称

  real_name = null; // 真实姓名

  headimgurl = null;

  mobile = null;

  app_token = null;

  uid = null;

  balance = null; // 账户余额

  point = null; // 积分

  grade = null; // 等级

  constructor(
    username,
    real_name,
    headimgurl,
    mobile,
    app_token,
    uid,
    balance,
    point,
    grade
  ) {
    this.username = username;
    this.real_name = real_name;
    this.headimgurl = headimgurl;
    this.mobile = mobile;
    this.app_token = app_token;
    this.uid = uid;
    this.balance = balance;
    this.point = point;
    this.grade = grade;
  }
  /**
   *
   * @param {用户名} app_id
   * @param {手机号} mobile
   * @param {验证码} verify
   * @param {密码} password
   */
  async userSignIn(mobile, password, verify) {
    const res = await post("/shop/user.login/register", {
      app_id: App_ID,
      mobile,
      verify,
      password
    });
    return res;
  }
  // 用户登录
  async Login(mobile, password) {
    try {
      const res = await post("/shop/user.login/index", {
        app_id: App_ID,
        mobile,
        password
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 签到记录
  async Sign_Records() {
    try {
      const res = await post("/sign/user.sign/index", {
        app_id: App_ID,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 微信注册
  async wxRegister(mobile, password, verify) {
    try {
      const res = await get("/user/user.wx_user/wxregister", {
        app_id: App_ID,
        wx_token: sessionStorage.getItem('access_token'),
        mobile,
        password,
        verify
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 微信账户登录
  async wx_login(mobile, password) {
    try {
      const res = await get("user/user.wx_user/wxlogin", {
        app_id: App_ID,
        wx_token: sessionStorage.getItem('access_token'),
        mobile,
        password,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 手机号一键登录（暂时不用）
  async wx_phoneLogin(mobile, verification_code) {
    try {
      const res = await get("user/user.index/mobile_login", {
        app_id: App_ID,
        wx_token: sessionStorage.getItem('access_token'),
        mobile,
        verification_code,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 石油H5登录
  async codeLogin(mobile, verification_code) {
    try {
      const res = await post("user/user.index/h5_login", {
        app_id: App_ID,
        mobile,
        verification_code,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }


  // 获取手机验证码
  async getPhoneCode(mobile) {
    try {
      const res = await post("user/user.index/send_code", {
        app_id: App_ID,
        mobile
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 充值记录
  async user_recharge(page, limit, pay_status, account_id) {
    try {
      const res = await post("/offlinestore/user.recharge/index", {
        app_id: App_ID,
        page,
        limit,
        pay_status,
        account_id
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 加油站 余额变动记录
  async balanceLogs(page) {
    try {
      const res = await post("/user/user.logs/balanceLogs", {
        app_id: App_ID,
        page
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 积分商城订单
  async getPointOrder(page, order_type) {
    try {
      const res = await post("/creditshop/user.creditshop_goods_order/index", {
        app_id: App_ID,
        page,
        order_type
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 加油站 积分记录
  async pointLogs(page) {
    try {
      const res = await post("/user/user.logs/pointLogs", {
        app_id: App_ID,
        page
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 加油站 余额变动详情
  async balanceOne(id) {
    try {
      const res = await post("/user/user.logs/balanceOne", {
        app_id: App_ID,
        id
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 判断是否是会员
  async isVip() {
    try {
      const res = await post("/user/user.User_level_Card/is_card", {
        app_id: App_ID,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 我的会员卡
  async my_card() {
    try {
      const res = await post("/client/user/user_card", {
        app_id: App_ID,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 我的会员卡
  async vip_listCard() {
    try {
      const res = await post("/user/user.User_level_card/index", {
        app_id: App_ID,
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 会员卡注册。
  async vipRegister(mobile, verification_code, reco_code) {
    try {
      const res = await get("/user/user.index/wx_member_register", {
        app_id: sessionStorage.getItem('appid') || localStorage.getItem('app_id'),
        wx_token: sessionStorage.getItem('access_token'),
        mobile,
        verification_code,
        reco_code
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  // 设置密码
  async setVipPwd(password, repassword) {
    try {
      const res = await post("/user/user.index/setPass", {
        app_id: App_ID,
        password,
        repassword
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  // 判断用户是否完善资料
  async isUserInfo() {
    try {
      const res = await post("/shop/user.index/judgeField", {
        app_id: App_ID,
        field: "password"
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 登录后获取用户信息
  async InfoUpdate(token) {
    try {
      const res = await get("/user/user.index/userInfo", {
        app_id: App_ID,
        token
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 重置token
  async refreshToken(token) {
    try {
      const res = await get("/user/user.index/refresh_token", {
        app_id: App_ID,
        token
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  //修改绑定手机号
  async changeBindPhone(mobile, code) {
    try {
      const res = await post("/user/user.index/changeBindMobile", {
        app_id: App_ID,
        mobile,
        code
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 完善信息
  async changeUserInfo(id, name, mobile, sex, birthday, device, product) {
    try {
      const res = await post("/user/user.User_level_card/edit_card", {
        app_id: App_ID,
        id,
        name,
        mobile,
        sex,
        birthday,
        device,
        product
      });
      return res;
    } catch (e) {
      console.log(e);
    }
  }


  //会员卡列表
  async vipCardList() {
    try {
      const res = await post("/user/agent.user_level/index", {
        app_id: App_ID
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 充值验证手机号
  async checkPhone(mobile, verification_code) {
    try {
      const res = await post("/user/user.index/verifyUser", {
        app_id: App_ID,
        mobile,
        verification_code
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 移除允许微信登录列表
  async removeWxAccount(verification_code, wx_id) {
    try {
      const res = await post("/wechat/user.account/removeWxAccount", {
        app_id: App_ID,
        verification_code,
        wx_id
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 允许微信登录列表
  async wxAccountList() {
    try {
      const res = await post("/wechat/user.account/wxAccountList", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 移除账号
  async removeAccount(verification_code, mobile) {
    try {
      const res = await post("/wechat/user.account/removeAccount", {
        app_id: App_ID,
        verification_code,
        mobile
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * 申请分销商
   * @param {第一个字段} first_field 
   * @param {第二个字段} second_field 
   * @param {第三个字段} third_field 
   * @param {第四个字段} fourth_field 
   * @param {第五个字段} fifth_field 
   */
  async openSpread(first_field, second_field, third_field, fourth_field, fifth_field) {
    try {
      const res = await post("/spread/user.apply/add", {
        app_id: App_ID,
        first_field,
        second_field,
        third_field,
        fourth_field,
        fifth_field,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * 获取申请分销商详情
   */
  async getSpread() {
    try {
      const res = await post("/spread/user.apply/detail", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * 获取分销商填写字段
   */
  async getSpreadInfoField() {
    try {
      const res = await post("/spread/user.apply/infoField", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 获取分销商设置信息
  async getSpreadSetInfo() {
    try {
      const res = await post("spread/user.index/spread", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 获取用户邀请码
  async getReco_code() {
    try {
      const res = await post("spread/user.index/reco_code", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 分销商收益
  async getCommission() {
    try {
      const res = await post("spread/user.index/commission", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 邀请佣金
  async getInviteCode() {
    try {
      const res = await post("spread/user.index/invite_commisson", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 根据邀请码获取信息
  async getRreco_codeInfo(reco_code) {
    try {
      const res = await post("spread/user.index/spreader", {
        app_id: App_ID,
        reco_code
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 新人礼包
  async openCardMoney() {
    try {
      const res = await post("spread/user.index/newer_gift", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 我的推广
  async getMySpread(page) {
    try {
      const res = await post("spread/user.index/mySpread", {
        app_id: App_ID,
        page,
        limit: 10
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 推广人排行
  async getPosSpread(limit = 10) {
    try {
      const res = await post("spread/user.index/positionSpread", {
        app_id: App_ID,
        limit
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 佣金记录
  async getCommissionLogs(page, limit = 10) {
    try {
      const res = await post("spread/user.index/commissionLogs", {
        app_id: App_ID,
        page,
        limit
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 立即提现
  async goCashMoney(money, account_type, account) {
    try {
      const res = await post("spread/user.withdrawal/apply", {
        app_id: App_ID,
        money,
        account_type,
        account
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 提现记录
  async getWithdrawal(page, limit = 10) {
    try {
      const res = await post("spread/user.withdrawal/logs", {
        app_id: App_ID,
        page,
        limit
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 分销商提现设置
  async getWithdConfig() {
    try {
      const res = await post("spread/user.withdrawal/config", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 分销推广订单
  async getSpreadOrder(page, limit = 5) {
    try {
      const res = await post("spread/user.index/spreadOrder", {
        app_id: App_ID,
        page,
        limit
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 充值订单
  async getSpreadRecharge(page, limit = 20) {
    try {
      const res = await post("spread/user.index/spreadRecharge", {
        app_id: App_ID,
        page,
        limit
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 获取手机号
  async getUserPhone() {
    try {
      const res = await post("user/user.index/mobile", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  // 会员列表
  async getLevel_cards() {
    try {
      const res = await post("/user/user.index/level_card", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  async addReceipt(order_id, receipt_id) {
    try {
      const res = await post("/offlinestore/user.order/add_receipt", {
        app_id: App_ID,
        order_id,
        receipt_id
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  
  async getUserMember() {
    try {
      const res = await post("/shop/user.index/info", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  async getUserQrcode() {
    try {
      const res = await post("/user/user.index/get_user_code", {
        app_id: App_ID,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 邀请活动详情
  async getInviteDetail() {
    return await post("client/invite/inviteDetail", {
      app_id: App_ID,
    });
  }
  // 支付宝H5 登录
  async aliH5Login(auth_code, reco_code) {
    return await post("client/ali.login/login", {
      app_id: App_ID,
      auth_code,
      reco_code
    });
  }

  // 支付宝生活号 注册
  async aliSign(mobile, verification_code, ali_token) {
    return await post("client/ali.login/register", {
      app_id: App_ID,
      mobile,
      verification_code,
      ali_token
    });
  }
  // 转移优惠券
  async moveUserCoupon(id, mobile, num) {
    return await post("coupon/user.coupon/give_coupon", {
      app_id: App_ID,
      id,
      mobile,
      num
    });
  }

  // 获取文章信息
  async getUserDetail(task_id) {
    return await post("client/adv/point_task_detail", {
      app_id: App_ID,
      task_id,
    });
  }

  // 获取用户充值信息
  async getConfigRecharge(card_id, card_type) {
    return await post("/user/user.ConfigRecharge/detail", {
      app_id: App_ID,
      card_id,
      card_type
    });
  }
  
}



export default new UserInfo();
