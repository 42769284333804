import Vue from 'vue'
import router from "@/router/index";
import Setting from "@/api/setting";
import { isWeixin }  from "@/utils/tools";
import { Dialog } from "vant";
const globalMixin = {
  // eslint-disable-next-line
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          appid: sessionStorage.getItem('appid'),
          isWeixin_status: isWeixin() ? true:false
        }
      },
      methods: {
        goBack() {
          /* eslint-disable no-unused-expressions */
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/vip')
        },
        async getAppConfig() {
          try {
            const res = await Setting.getPageConfig();
            return res.data.value || [];
          } catch (error) {
            console.log(error);
          }
        },
        goLocalStorageUrl() {
          router.push(`${sessionStorage.getItem('path')}`);
        },
        // 快速排序
        quickSort(arr) {
          if (arr.length <= 1) {
            return arr;
          }
          var pivotIndex = Math.floor(arr.length / 2);
          var pivot = arr.splice(pivotIndex, 1)[0];
          var left = [];
          var right = [];
          for (var i = 0; i < arr.length; i++) {
            if (Number(arr[i].dis) < Number(pivot.dis)  ) {
              right.push(arr[i]);
            } else {
              left.push(arr[i]);
            }
          }
          return this.quickSort(right).concat([pivot], this.quickSort(left));
        },
        // 获取个人信息
        async getUserInfo() {
          try {
            const res = await Setting.GetInfo();
            if (res.code == 200) {
              return res.data;
            } else if (res.code == 0) {
              if (isWeixin()) {
                Dialog.confirm({
                  title: res.msg,
                  confirmButtonText: "去登陆"
                }).then(() => {
                  router.push('/bindPhone')
                }).catch(() => {})
              } else {
                Dialog.confirm({
                  title: res.msg,
                }).then(() => {
                  router.push('/userlogin')
                }).catch(() => {})
              }
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      filters: {
        // ...filter,
      },
      computed: {
        // ...mapGetters([
        //   'user',
        // ]),
      }
    })
  },
}

Vue.use(globalMixin)

export default globalMixin
