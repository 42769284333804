// ajax 封装插件, 使用 axios
import Vue from 'vue'
import axios from 'axios'
import { Toast } from 'vant';
import router from '@/router/index';
import { isWeixin } from '@/utils/tools';

export const BaseURL = "https://www.58zltc.com";

const config = {
  baseURL: BaseURL,
  timeout: 100 * 1000, // 请求超时时间设置
  crossDomain: true,
  validateStatus(status) {
    return status >= 200 && status < 510
  },
}

// 创建请求实例
const _axios = axios.create(config)

_axios.interceptors.request.use((originConfig) => {
  const reqConfig = { ...originConfig }
  // step1: 容错处理
  if (!reqConfig.url) {
    /* eslint-disable-next-line */
    throw new Error({
      source: 'axiosInterceptors',
      message: 'request need url',
    })
  }


  var token = sessionStorage.getItem('access_token');
    // localStorage.getItem('access_token') ? localStorage.getItem('access_token') : sessionStorage.getItem('access_token');
  var app_id = sessionStorage.getItem('appid') || localStorage.getItem('app_id');
    // localStorage.getItem('app_id') ? localStorage.getItem('app_id') : sessionStorage.getItem('appid');

  if (!reqConfig.method) { // 默认使用 get 请求
    reqConfig.method = 'get'
  }
  // 大小写容错
  reqConfig.method = reqConfig.method.toLowerCase()

  // 设置token
  reqConfig.headers.Token = token;

  // 参数容错
  if (reqConfig.method === 'get') {
    if (reqConfig.params.hasOwnProperty('wx_token')) {
      reqConfig.headers.Token = "";
    }
    if (!reqConfig.params) { // 防止字段用错
      reqConfig.params = reqConfig.data || {}
    }
  } else if (reqConfig.method === 'post') {
    reqConfig.data.app_id = app_id;
    if (!reqConfig.data) { // 防止字段用错
      reqConfig.data = reqConfig.params || {}
    }
    if (reqConfig.url.indexOf('send_code')>0) {
      reqConfig.headers.Token = "";
    }
    // 检测是否包含文件类型, 若包含则进行 formData 封装
    // 检查子项是否有 Object 类型, 若有则字符串化
    let hasFile = false
    Object.keys(reqConfig.data).forEach((key) => {
      if (typeof reqConfig.data[key] === 'object') {
        const item = reqConfig.data[key]
        if (item instanceof FileList || item instanceof File || item instanceof Blob) {
          hasFile = true
        } else if (Object.prototype.toString.call(item) === '[object Object]') {
          reqConfig.data[key] = JSON.stringify(reqConfig.data[key])
        }
      }
    })

    // 检测到存在文件使用 FormData 提交数据
    if (hasFile) {
      const formData = new FormData()
      Object.keys(reqConfig.data).forEach((key) => {
        formData.append(key, reqConfig.data[key])
      })
      reqConfig.data = formData
    }
  } else {
    // TODO: 其他类型请求数据格式处理
    /* eslint-disable-next-line */
    console.warn(`其他请求类型: ${reqConfig.method}, 暂无自动处理`)
  }

  return reqConfig
}, (error) => {
  Promise.reject(error)
})

// Add a response interceptor
_axios.interceptors.response.use(async (res) => {
  return new Promise(async (resolve, reject) => {
    const { params } = res.config
    const { code} = res.data;
    switch (code) {
      case 0:
        if (isWeixin()) {
          let redirectUrl = encodeURIComponent(window.location.href);
          console.log(redirectUrl);
          let app_id = localStorage.getItem('app_id');
          var qrcode_id = localStorage.getItem('qrcode_id');
          window.location.href = 
            `https://www.58zltc.com/wechat/user.login/snsapi_base?app_id=${app_id}&qrcode_id=${qrcode_id}&callback=${redirectUrl}`;
        } else {
          router.push('/userLogin')
        }
        resolve(res.data);
        break;
      case 500:
        setTimeout(() => {
          Toast("网络开小差了...");
        }, 1500);
        resolve(res.data);
        break;
      case 400:
        resolve(res.data);
        break;
      default:
        resolve(res.data);
    }
    // 本次请求添加 params 参数：handleError 为 true，用户自己try catch，框架不做处理
    if (params && params.handleError) {
      reject(res)
      return
    }
  })
}, (error) => {
  if (!error.response) {
    console.log(error)
    Toast.fail('网络异常...');
    // router.push("/beUpdating")
  }

  // 判断请求超时
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    Toast.fail('请求超时');
  }
  return Promise.reject(error)
})


// eslint-disable-next-line
Plugin.install = function (Vue, options) {
  // eslint-disable-next-line
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

if (!Vue.axios) {
  Vue.use(Plugin)
}

// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function post(url, data = {}, params = {}) {
  return _axios({
    method: 'post',
    url,
    data,
    params,
  })
}

/**
 * @param {string} url
 * @param {object} params
 */
export function get(url, params = {}) {
  return _axios({
    method: 'get',
    url,
    params,
  })
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function put(url, data = {}, params = {}) {
  return _axios({
    method: 'put',
    url,
    params,
    data,
  })
}

/**
 * @param {string} url
 * @param {object} params
 */
export function _delete(url, params = {}) {
  return _axios({
    method: 'delete',
    url,
    params,
  })
}

export default _axios
