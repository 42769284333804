function ready(callback) {
  if (window.AlipayJSBridge) {
    callback && callback();
  } else {
    document.addEventListener('AlipayJSBridgeReady', callback, false);
  }
}

// 支付宝支付
export function aliPay(tradeNO) {
  return new Promise((resolve, reject) => {
    ready(function () {
      // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
      window.AlipayJSBridge.call("tradePay", {
        tradeNO: tradeNO
      }, function (data) {
        if ("9000" == data.resultCode) {
          resolve(true)
        } else {
          reject(false)
        }
      });
    });
  })
}

// 获取支付宝code
export function getAliUserInfo() {
  return new Promise((resolve) => {
    window.ap.getAuthCode(
      {
        appId: 2021002131618639,
        scopes: ['auth_base'],
      }, (res) => {
        console.log(res)
        if (res.authCode) {
          resolve(res.authCode)
        } else {
          resolve(false)
        }
      }
    )
  })
}

