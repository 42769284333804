import { getUrlArgObject }  from "./tools";
export function initUrl() {
  var url = getUrlArgObject();

  if (url.hasOwnProperty('app_id')) {
    if (url.app_id.indexOf('?') > 0) {
      url.app_id = url.app_id.split('?')[0];
      localStorage.setItem("app_id", url.app_id);
      sessionStorage.setItem("appid", url.app_id)
    } else {
      localStorage.setItem("app_id", url.app_id);
      sessionStorage.setItem("appid", url.app_id)
    }
  }
  if (url.hasOwnProperty('appid')) {
    if (url.appid.indexOf('?') > 0) {
      url.appid = url.appid.split('?')[0];
      localStorage.setItem("app_id", url.appid);
      sessionStorage.setItem("appid", url.appid)
    } else {
      localStorage.setItem("app_id", url.appid);
      sessionStorage.setItem("appid", url.appid)
    }
  }
  if (url.wx_token) {
    localStorage.setItem("access_token", url.wx_token);
    sessionStorage.setItem("access_token", url.wx_token)
  }
  if (url.app_token) {
    localStorage.setItem("access_token", url.app_token);
    sessionStorage.setItem("access_token", url.app_token)
  }
  if (url.qrcode_id) {
    localStorage.setItem("qrcode_id", url.qrcode_id);
    sessionStorage.setItem("qrcode_id", url.qrcode_id);
  } 
  // 洗车服务跳转
  if (url.washStatus) {
    localStorage.setItem("pageId", url.pageId);
    sessionStorage.setItem("pageId", url.pageId);
    localStorage.setItem("choseServe", url.choseServe);
    sessionStorage.setItem("choseServe", url.choseServe);
    localStorage.setItem("washStatus", url.washStatus);
    sessionStorage.setItem("washStatus", url.washStatus);
  } 
}
