import Vue from "vue";
import "@/lin/mixin"; // 挂载全局通用函数
import "@/lin/filter";
import "@/lin/plugins";
import App from "./App.vue";
import store from "./store/index"
import router from "./router/index";
import ModalHelper from "@/utils/modalHelper";
import VueClipboard from 'vue-clipboard2';
import { Lazyload, Toast, Image, Notify, Dialog, Tab, Tabs} from "vant";
import "@/assets/css/all.scss";
import "vant/lib/index.css";
import NP from 'number-precision';
import { initUrl } from "@/utils/initUrl/";
Vue.config.productionTip = false;

Vue.use( Lazyload, Toast, Image, Notify, Dialog,
	Tab,
	Tabs,
	{
		lazyComponent: true
	}
);
// 初始化地址栏参数
initUrl()

// 全局弹窗提示展示
Toast.setDefaultOptions({ duration: 800 });

// 挂载全局遮罩层变量
Vue.prototype.$modalHelper = ModalHelper;
// 计算
Vue.prototype.$formCode = NP;

Vue.use(VueClipboard)

// 挂载全局Appid
new Vue({
	render: h => h(App),
	router,
	store
}).$mount("#app");
