import { Toast } from "vant";
import userLogin from "@api/user";
import router from "@/router/index";
import { getToken } from "@/lin/utils/token";

/**
 * 用户模块状态操作
 */
 const UserInfo = {
  state: {
    token: "",
    Login_Status: false,
    addressId: "",
    invoiceId: "",
    whether_h5_wx: true // false 代表h5, true 代表微信
  },
  mutations: { 
    setToken (state, token) {
      state.token = token;
    },
    getStoreToken (state) {
      return getToken(state.token);
    },
    setLoginStatus (state, status) {
      state.Login_Status = status;
    },
    setAddressId(state, addressId) {
      state.addressId = addressId;
    },
    setInvoiceId(state, invoiceId) {
      state.invoiceId = invoiceId;
    },
    setWhetherwx(state, whether_h5_wx) {
      state.whether_h5_wx = whether_h5_wx
    }
  },
  actions: { 
    async userLogin({ commit }, data) {
      const { mobile, password, path } = data;
      try {
        const res = await userLogin.Login(mobile, password);
        if (res.code == 200) {
          commit('setLoginStatus', true);
          Toast("登录成功");
          sessionStorage.setItem('access_token', res.data.app_token);
          sessionStorage.setItem('userInfo', JSON.stringify(res.data));
          router.push("/"+path);
        } else {
          throw `${res.msg}`;
        }
      } catch(e) {
        Toast(e);
      }
    }
  },
  getters: { }
}

export default UserInfo
