<template>
  <div id="app">
    <transition name="fade">
      <router-view :key="time"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      num: 1,
      time: new Date().getTime()
    };
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  min-height: 100vh;
  max-width: 1500px;
  margin: 0 auto;
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>