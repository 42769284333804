export const Tools = [
  {
    path: "/wxScan",
    component: () => import("@/views/tools/wxScan")
  },
  {
    path: "/upload-imgs",
    component: () => import("@/views/tools/uploadImgs")
  },
  {
    path: "/paySuccess",
    component: () => import("@/views/tools/paySuccess")
  }
]
