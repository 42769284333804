
import { getUrlArgObject } from "@/utils/tools";
import { getSystemEnv } from '@/utils/tools'
import { getAliUserInfo } from '@/utils/alipay';
import UserInfo from '@/api/user';
import Setting from '@/api/setting';

export async function setConfig(to, from, next) {
  const isEnv = getSystemEnv();
	// 设置标题名称
	if (to.name) document.title = to.name;
	if (to.matched.some(recode => recode.meta.noAuth)) {
    next()
	} else {
    var url = getUrlArgObject();
    var token = sessionStorage.getItem("access_token");
    var token_2 = url.wx_token || url.app_token;
    if (isEnv === 'wx') {
      let str = to.path;
      Setting.isUsedNewPay(str).then(res => {
        if (res.code == 200 && Object.keys(url).length == 2) {
          window.location.href = 
            `http://xzf.58zltc.com/cardpay${window.location.search}&app_id=${url.appid}`;
        } else {
          if (token || token_2) {
            // 初始化本地参数
            next();
          } else {
            var app_id = localStorage.getItem('app_id');
            var redirectUrl = window.location.href;
            var qrcode_id = localStorage.getItem('qrcode_id');
            // 分享url 标识
            var get_code = getUrlArgObject().get_code;
            // 站点列表标识
            var list_code = getUrlArgObject().list_code;
            var app_str = getUrlArgObject().app_str;
            if (get_code) {
              next();
            } else if(list_code) {
              let back = redirectUrl.split('?')[0];
              let url = encodeURI("https://www.58zltc.com/wechat/user.login/leader?app_id=" + app_id  + "&callback=" + back);
              window.location.href = url;
            } else if(app_str) {
               next();
            } else {
              let url = encodeURI("https://www.58zltc.com/wechat/user.login/snsapi_base?app_id=" + app_id + "&qrcode_id=" + qrcode_id + "&callback=" + redirectUrl);
              window.location.href = url;
            }
          }
        }
      })
    } else if (isEnv === 'ali') {
      if (token || token_2) {
        next();
      } else {
        const auth_code = await getAliUserInfo();
        if (auth_code) {
          const info = await UserInfo.aliH5Login(auth_code);
          if (info.code === 200) {
            const { app_token } = info.data;
            localStorage.setItem("access_token", app_token)
            sessionStorage.setItem("access_token", app_token)
            next();
          } else {
            const { ali_token } = info.data;
            const url_param = getUrlArgObject();
            localStorage.setItem("access_token", ali_token)
            sessionStorage.setItem("access_token", ali_token)
            if (url_param.qrcode_id) {
              sessionStorage.setItem('path', `/cardpay?qrcode_id=${url_param.qrcode_id}&app_id=${url_param.app_id}`);
            }
            next('/userlogin?ali_token='+ali_token+'&is_status=ali');
          }
        }
      }
    } else if (isEnv === 'h5') {
      next();
    }
  }
}

