import NP from "number-precision";
/* eslint-disable */
//返回的是字符串形式的参数，例如：class_id=3&id=2&
function getUrlArgStr() {
  var q = location.search.substr(1);
  var qs = q.split("&");
  var argStr = "";
  if (qs) {
    for (var i = 0; i < qs.length; i++) {
      argStr +=
        qs[i].substring(0, qs[i].indexOf("=")) +
        "=" +
        qs[i].substring(qs[i].indexOf("=") + 1) +
        "&";
    }
  }
  return argStr;
}
//返回的是对象形式的参数
function getUrlArgObject(url) {
  var args = new Object();
  var query = url ? url : location.search.substring(1); //获取查询串
  var pairs = query.split("&"); //在逗号处断开
  for (var i = 0; i < pairs.length; i++) {
    var pos = pairs[i].indexOf("="); //查找name=value
    if (pos == -1) {
      //如果没有找到就跳过
      continue;
    }
    var argname = pairs[i].substring(0, pos); //提取name
    var value = pairs[i].substring(pos + 1); //提取value
    args[argname] = unescape(value); //存为属性
  }
  return args; //返回对象
}

function isMiniEnv() {
  let status =
    (navigator.userAgent.match(/micromessenger/i) &&
      navigator.userAgent.match(/miniprogram/i)) ||
    window.__wxjs_environment === "miniprogram";
  return status;
}

// 判断微信环境
function isWeixin() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
}

function isAliPay() {
  return new Promise((resolve) => {
    if (window.my) {
      window.my.getEnv(function(res) {
        if (res.miniprogram) resolve(true);
        resolve(false)
      });
    } else {
      resolve(false)
    }
  })
}

function getSystemEnv() {
  var payEnv;
  if (/MicroMessenger/.test(window.navigator.userAgent)) {
    payEnv = "wx";
  } else if (window.navigator.userAgent.indexOf('AliApp') > -1) {
    payEnv = "ali";
  } else {
    payEnv = "h5";
  }
  console.log(payEnv)
  return payEnv;
}

function getElements(formId) {
  var form = document.getElementById(formId);
  var elements = new Array();
  var tagElements = form.getElementsByTagName("input");
  for (var j = 0; j < tagElements.length; j++) {
    elements.push(tagElements[j]);
  }
  return elements;
}

// url 增加时间戳
function timeStamp(url) {
  var getTimestamp = new Date().getTime();
  if (url.indexOf("?") > -1) {
    url = url + "&timestamp=" + getTimestamp;
  } else {
    url = url + "?timestamp=" + getTimestamp;
  }
  return url;
}

// 经纬度计算两地之间距离
function getDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = (lat1 * Math.PI) / 180.0;
  var radLat2 = (lat2 * Math.PI) / 180.0;
  var a = radLat1 - radLat2;
  var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    );
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000;
  s = Number.parseFloat(s).toFixed(2);
  return s;
}

function debounce(func, wait) {
  let timeout = null;
  return function() {
    let context = this;
    let args = arguments;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

/**
 *
 * @param {优惠方式} arr
 * @param {用户金额} user_money
 */
function setMoney(arr, user_money, all_dismoney) {
  try {
    if (all_dismoney) {
      let dis_money = 0,
        end_money = 0;
      if (user_money == "") user_money = 0;
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          switch (arr[i]) {
            case "1":
              dis_money += Number(all_dismoney.point_dec_money);
              break;
            case "2":
              dis_money += Number(all_dismoney.coupon_dec_money);
              break;
            case "3":
              dis_money += Number(all_dismoney.discount_dec_money);
              break;
          }
        }
        return {
          dis_money: NP.round(dis_money, 2),
          end_money: NP.round(user_money - dis_money, 2),
        };
      } else {
        return {
          dis_money: 0,
          end_money: user_money,
        };
      }
    } else {
      console.log("没有优惠...");
    }
  } catch (error) {
    console.log(error);
  }
}

export {
  getUrlArgStr,
  getUrlArgObject,
  getElements,
  isWeixin,
  timeStamp,
  getDistance,
  debounce,
  setMoney,
  isMiniEnv,
  isAliPay,
  getSystemEnv,
};
