
import Vuex from 'vuex'
import Vue from 'vue'
import UserInfo from "./user"
import DiscountInfo from "./dis_money"
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user: UserInfo,
    discountInfo: DiscountInfo
  }
})
export default store
